.pageTitle {
  font-size: var(--font-size-xxl);
  position: relative;
  bottom: -23px;
  margin-bottom: 0;
  @media (max-width: 992px) {
    font-size: var(--font-size-lg);
  }
  @media (max-width: 768px) {
    bottom: 0;
  }
}
.heroSliderContainer {
  position: relative;
  width: 100%;
  margin: auto;

  @media (max-width: 768px) {
    min-height: 50dvh;
  }
  @media (max-width: 500px) {
    min-height: 320px;
    // max-height: 600px;
  }
  //   @media (max-width: 480px) {
  //   min-height: 92dvh;
  // }
}
.featuredSection {
  min-height: 300px;
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-sm);
  @media (max-width: 610px) {
    margin-top: -10px;
  }
  @media (max-width: 570px) {
    margin-top: -30px;
  }
  @media (max-width: 500px) {
    margin-top: var(--spacing-xs);
  }
  // @media (max-width: 390px) {
  //   margin-top: -15px;
  // }
}
