.favorite {
  position: absolute;
  top: 5px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: max-content;
  padding: 6px var(--spacing-xxs) var(--spacing-xxs) var(--spacing-xxs);
  border: none;
  border-radius: var(--border-radius-md);
  background: none;
  transition: var(--transition-md);
  z-index: 50;
  &:hover {
    cursor: pointer;
    background: none !important;
    background-color: none;
    transform: scale(1.1);
  }

  &.loading {
    opacity: 0.7;
    transform: scale(0.96);
  }

  .signIn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;

    &:hover {
      background: none !important;
      background-color: none;
    }
  }

  :global {
    .sign-up-sign-in-button {
      &:hover {
        background: transparent !important;
        color: var(--light-text) !important;
      }
    }
  }
}
