.linkCard {
  flex: 1 1 31%;
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  // width: clamp(260px, 33%, 100%);
  min-width: 160px;
  max-width: 374px;
  // padding: 0 12px;
  transition: 0.3s;
  margin-bottom: 30px;
  // margin-left: auto;
  // margin-right: var(--spacing-md);
  //   box-shadow: 0 0 6px 0 rgba(80, 80, 71, 0.23);
  //   filter: drop-shadow(0 4px 20px rgba(0, 75, 187, 0.05));
  border-radius: 5px;
  overflow: visible;
  transition: all 0.3s ease-in-out;
  pointer-events: all;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 580px) {
    flex: 1 1 100%;
    // min-width: 200px;
    margin: 0 auto var(--spacing-md) auto;
  }
  // @media (min-width: 520px) and (max-width: 768px) {
  //   flex: 0 1 auto;
  //   // width: 48%;
  // }
  @media (min-width: 1200px) {
    flex: 0 0 31%;
    // max-width: 33%;
  }

  .link {
    display: block !important;
    width: 100%;

    div {
      div:global(.divider-line-no) {
        margin-bottom: 16px;
      }
    }
  }

  .imageWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 14px;
    aspect-ratio: 16 / 9;
    overflow: hidden;

    .image {
      object-fit: cover;
      border-radius: 10px;
    }

    .noImage {
      position: relative;
      min-width: 100%;
      aspect-ratio: 140 / 73;
      background: hsla(0, 0%, 0%, 0.5);
      border-radius: 10px;
    }

    .tag {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .overlay {
      position: absolute;
      display: flex;
      width: 50%;
      height: fit-content;
      max-height: 40%;

      &.overlayTopLeft {
        align-items: flex-start;
        top: 0;
        left: 0;
        background: linear-gradient(
          135deg,
          hsla(0, 0%, 0%, 0.8) 0%,
          hsla(0, 0%, 0%, 0.4) 30%,
          hsla(0, 0%, 0%, 0) 100%
        );
        border-radius: var(--border-radius-lg) 0 0 var(--border-radius-lg);
      }

      &.overlayTopRight {
        justify-content: flex-end;
        align-items: flex-start;
        top: 0;
        right: 0;
        background: linear-gradient(
          285deg,
          hsla(0, 0%, 0%, 0.6) 0%,
          hsla(0, 0%, 0%, 0.2) 30%,
          hsla(0, 0%, 0%, 0) 80%
        );
        border-radius: 0 var(--border-radius-lg) 0 var(--border-radius-lg);
      }

      &.overlayBottomLeft {
        align-items: flex-end;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          45deg,
          hsla(0, 0%, 0%, 0.8) 0%,
          hsla(0, 0%, 0%, 0.4) 30%,
          hsla(0, 0%, 0%, 0) 100%
        );
        border-radius: 0 var(--border-radius-lg) 0 var(--border-radius-lg);
      }

      &.overlayBottomRight {
        align-items: flex-end;
        bottom: 0;
        right: 0;
        background: linear-gradient(
          225deg,
          hsla(0, 0%, 0%, 0.8) 0%,
          hsla(0, 0%, 0%, 0.4) 30%,
          hsla(0, 0%, 0%, 0) 100%
        );
        border-radius: 0 0 var(--border-radius-lg) 0;
      }

      &.fullWidthOverlay {
        width: 100%;
      }
    }
  }
  .cardContent {
    position: relative;
    min-height: max-content;

    .cardTitle {
      position: relative;
      text-align: left;
      font-size: var(--font-size-md);
      transition: all 0.3s ease-in-out;
      color: var(--dark-text);
      font-weight: 500;
      margin: 0 0 8px 0;
      line-height: normal;
      z-index: 5;
      height: 2.5em;

      @media (min-width: 520px) and (max-width: 992px) {
        font-size: calc(1.15rem + 0.3vw);
      }
      @media (max-width: 480px) {
        height: 2.6em;
        margin-bottom: 0;
        font-size: var(--font-size-md-sm);
      }
      @media (max-width: 380px) {
        height: 2.6em;
        margin-bottom: 0;
        font-size: var(--font-size-md);
      }
    }

    .dataContainer {
      display: flex;
      align-items: center;
      color: var(--dark-text);

      @media (min-width: 520px) and (max-width: 768px) {
        font-size: var(--font-size-xs);
      }
      .data {
        &.data1 {
          flex: 1 1 auto;
          display: flex;
          align-items: center;
        }
        &.data2 {
          flex: 1 1 auto;
          min-width: 30%;
          text-align: right;
          font-weight: 500;
        }
      }
    }
    .readMore {
      flex: 1 1 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      color: var(--dark-text);

      & span {
        margin-left: 12px;
        font-size: 16px;
        margin-top: 3px;
        height: 100%;

        &.readMoreText {
          display: flex;
          height: 100%;
          padding-bottom: 5px;
          line-height: 1.3;
          text-align: center;
        }
      }
      &.readMoreBlock {
        justify-content: flex-start;
        padding-top: 0.5rem;
      }
    }
  }
}
