.locationInfo {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs);
  color: var(--light-text);
  text-shadow: 2px 2px 1px var(--dark-text);
  .locationIcon {
    margin-right: 0.5rem;
  }
  .secondary {
    font-size: var(--font-size-md-sm);
  }
  .primary {
    font-weight: 500;
    font-size: var(--font-size-md);
  }
}

.tripLength {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs);
  color: var(--light-text);
  text-shadow: 1px 1px 1px var(--dark-text);

  .tripLengthIcon {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }

  .tripLengthText {
    font-size: var(--font-size-md-sm);
    font-weight: 500;
  }
}

.travelGuideLinkCard {
  :global {
    .wn-favorite-button {
      // right: 110px;
      left: 10px;
      right: unset;
      width: fit-content;

      .sign-in-button {
        display: block;
        left: 0;
        width: fit-content;
        padding-left: 0;
        margin-left: 0;
      }
    }
  }
}
