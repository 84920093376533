.slider {
  position: relative;

  // height: 100%;

  @media (max-width: 768px) {
    border-bottom: 1px solid #afafaf;
  }

  .container {
    // max-width: max-content;
    width: 100%;
    // height: 100%;

    .trackContainer {
      // max-width: max-content;
      @media (max-width: 1100px) {
        max-height: 40vw;
      }
      @media (max-width: 992px) {
        max-height: 50vw;
      }
      @media (max-width: 768px) {
        max-height: 70vw;
      }
      @media (max-width: 600px) {
        max-height: 75vw;
      }
      @media (max-width: 480px) {
        max-height: 80vw;
      }
      @media (max-width: 400px) {
        max-height: 85vw;
      }
      @media (max-width: 380px) {
        max-height: 95vw;
      }

      .track {
        width: 100%;
        // max-width: max-content;
        // height: 100%;
        @media (max-width: 768px) {
          height: auto;
          // max-height: 100%;
        }
      }
    }
  }

  &.withSideNav {
    display: grid;
    grid-template-columns: 3fr 1fr;

    @media (min-width: 1100px) {
      padding-right: var(--spacing-xxs);
    }
    @media (max-width: 1100px) {
      grid-template-columns: 100%;

      // grid-template-rows: minmax(400px, 100%) 120px;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    .container {
      grid-column: 1;
      grid-row: 1;
    }

    .sideNav {
      position: relative;
      grid-column: 2;
      grid-row: 1;
      justify-self: start;
      height: 100%;
      padding-top: var(--spacing-xs);
      padding-left: var(--spacing-xs);
      overflow-y: auto;
      overflow-x: hidden;
      // -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: auto; /* Firefox */

      @media (max-width: 1100px) {
        display: none;
        overflow-y: hidden;
        grid-column: 1;
        grid-row: 2;
      }

      @media (max-width: 1100px) {
        min-height: 170px;
        height: auto;
      }

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: block;
        width: 0.5em;
        overflow: auto;
        height: 2em;
        // display: none;

        &:hover {
          width: 0.5em;
          cursor: grab;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: var(--dark-background-transparent);
        border-radius: 5px;

        &:hover {
          opacity: 0.5;
        }
      }

      .sideNavItems {
        position: absolute;
        display: flex;
        flex-direction: column;
        padding-bottom: var(--spacing-xxl);

        @media (max-width: 1100px) {
          flex-direction: row;
          padding-bottom: 0;
        }
      }
    }
    .scrollButtonsContainer {
      grid-column: 2;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
  // .sliderNavigation {
  //   position: absolute;
  //   top: 45%;
  //   display: flex;
  //   justify-content: space-between;
  //   width: 100%;
  .navButton {
    position: absolute;
    top: 45%;
    border: none;
    background: var(--light-background-translucent);
    margin: 0 var(--spacing-sm);
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    @media (max-width: 768px) {
      // display: none;
      top: 45%;
    }
    &.withTextBar {
      top: 48%;
      @media (max-width: 768px) {
        top: 35%;
      }

      @media (max-width: 600px) {
        top: 32%;
      }
      @media (max-width: 480px) {
        top: 25%;
      }
    }
    &:hover {
      color: var(--dark-text) !important;
      background-color: var(--light-background-translucent) !important;
      opacity: 0.8;
    }

    &.previous {
      left: 8px;
      padding-left: 4px;
      padding-right: 6px;
      padding-bottom: 1px;
      padding-top: 4px;
    }
    &.next {
      right: 8px;
      padding-left: 6px;
      padding-right: 4px;
      padding-bottom: 1px;
      padding-top: 4px;
    }
  }
  // }
  .pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 15px;

    button {
      margin: 0 var(--spacing-xxs);
      width: 20px;
      height: 20px;
      border: none;
      border-radius: 50%;
      background: var(--light-background-translucent);
      color: transparent;
      font-size: 0;
      transition:
        background 0.2s ease-in-out,
        color 0.27s ease-in-out;

      @media (max-width: 768px) {
        width: 22px;
        height: 22px;
      }

      &:global(.active) {
        background: var(--accent-1);
        color: transparent;
      }
    }
  }
}
